.proImg .swiper {
    width: 100%;
    min-height: 100%;
    padding: 30px 0;
  }
  
  .proImg  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
}
  
  .proImg  .swiper-slide img {
    display: block;
    width: 100%;
    height: 250PX;
    object-fit: cover;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.proImg .swiper-horizontal > .proImg .swiper-pagination-bullets,
.proImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

@media screen and (max-width: 767px) {
 
.proImg .swiper {
    width: 100%;
    min-height: 100%;
    margin-bottom: 30px;
  }
.proImg .swiper-horizontal > .proImg .swiper-pagination-bullets,
.proImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:97% !important;
  display: block;
}
.proImg .swiper-pagination-bullet-active {
    background-color:#e85530 ;
    height: 11px;
    width: 11px;
    }
    .proImg  .swiper-slide img {
      width: 85% !important;
      margin-right: 40px;
  }
  body[dir="rtl"]  .proImg  .swiper-slide img {
    width: 85% !important;
    margin-right: 0px !important;
    margin-left: 40px !important;
}
}


@media screen and (min-width:768px) and (max-width:991px){
    .proImg .swiper {
        width: 100%;
        min-height: 100%;
        margin-bottom: 30px;
      }
      .proImg .swiper-horizontal > .proImg .swiper-pagination-bullets,
.proImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:96.3% !important;
  display: block;
}
.proImg .swiper-pagination-bullet-active {
    background-color:#e85530 ;
    height: 11px;
    width: 11px;
    }
}