@import "../../../index.scss";


.mainGallery{
    margin-top: 60px;
    min-height: 100vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        margin-bottom: 40px;
        h2{
            font-size: 38px;
            color: $DarkBlueColor;
        }
    }
    .galleryInfo{
        .galleryTabs{
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 30px;
            .right{
                width: 50% !important;
                display: flex;
                justify-content: center;
                align-items: center;
                .navGallery{
                    border: 1px solid #d0d7dd !important;
                    width: 100%;
                    transition: 1s all ease;
                    -webkit-transition: 1s all ease;
                    -moz-transition: 1s all ease;
                    -ms-transition: 1s all ease;
                    -o-transition: 1s all ease;
                    color: $DarkBlueColor;
                    border-top-left-radius:0 !important;
                    border-top-right-radius:0 !important;
                }
            }
            .left{
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .navGallery{
                    border: 1px solid #d0d7dd !important;
                    width: 100%;
                    transition: 1s all ease;
                    -webkit-transition: 1s all ease;
                    -moz-transition: 1s all ease;
                    -ms-transition: 1s all ease;
                    -o-transition: 1s all ease;
                    color: $DarkBlueColor;
                    border-top-left-radius:0 !important;
                    border-top-right-radius:0 !important;
                }
            }
        }
        .tabContent{
            padding-top: 40px;
        }
    }
}




@media screen and (max-width: 767px) {
    .mainGallery{
        margin-top: 60px;
        min-height: 100vh;
        .head{
            h2{
                font-size: 25px;
                text-align: center;
            }
        }
        .galleryInfo{
            .galleryTabs{
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-size: 20px;
                .right{
                    width: 50% !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .navGallery{
                        border: 1px solid #d0d7dd !important;
                        width: 100%;
                        transition: 1s all ease;
                        -webkit-transition: 1s all ease;
                        -moz-transition: 1s all ease;
                        -ms-transition: 1s all ease;
                        -o-transition: 1s all ease;
                        color: $DarkBlueColor;
                        border-top-left-radius:0 !important;
                        border-top-right-radius:0 !important;
                    }
                }
                .left{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .navGallery{
                        border: 1px solid #d0d7dd !important;
                        width: 100%;
                        transition: 1s all ease;
                        -webkit-transition: 1s all ease;
                        -moz-transition: 1s all ease;
                        -ms-transition: 1s all ease;
                        -o-transition: 1s all ease;
                        color: $DarkBlueColor;
                        border-top-left-radius:0 !important;
                        border-top-right-radius:0 !important;
                    }
                }
            }
            .tabContent{
                padding-top: 40px;
            }
        }
    }
    body[dir='rtl']{
        .mainGallery{
            .head{
                h2{
                    font-size: 25px;
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .mainGallery{
        margin-top: 60px;
        min-height: 70vh;
        .head{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            h2{
                font-size: 45px;
                color: $DarkBlueColor;
                text-align: center;
            }
        }}
}