@import "../../../index.scss";

.mobNav {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .mobLeft {
    width: 30%;
    padding: 10px;
    .logo {
      img {
        width: 100%;
      }
    }
  }
  .mobRight {
    .icon {
      font-size: 30px;
      color: $DarkBlueColor;
    }
  }
}
.mobBar {
  background-color: $DarkBlueColor;
  height: 0;
  position: absolute;
  z-index: 99999;
  width: 100%;
  top: 0;
  padding: 0px 20px;
  .head {
    display: flex;
    justify-content: space-between;
    .logo {
      width: 27%;
      img {
        width: 100%;
      }
    }
    .center {
      margin-top: 6px;
      color: #eee;
      font-size: 20px;
    }
    .icon {
      color: #eee;
      font-size: 33px;
    }
  }
  .body {
    padding: 15px;
    .item {
      display: flex;
      margin-top: 10px;
      border-bottom: 0.5px solid #4e7a9a;
      i {
        color: $SecMainColor;
      }
      .links {
        color: #eee;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 15px;
      }
    }
  }
  .footer {
    .myBtn {
      margin: 30px 0 20px;
    }
    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 15px;
      a {
        color: #eee;
        margin-left: 15px;
        font-size: 30px;
      }
    }
    .copy {
      color: #eee;
      font-size: 14px;
      text-align: center;
    }
  }
}

body[dir="rtl"] {
  .mobNav {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .mobLeft {
      width: 30%;
      padding: 10px;
      .logo {
        img {
          width: 100%;
        }
      }
    }
    .mobRight {
      .icon {
        font-size: 30px;
        color: $DarkBlueColor;
      }
    }
  }
  .mobBar {
    background-color: $DarkBlueColor;
    height: 0;
    position: absolute;
    z-index: 99999;
    width: 100%;
    top: 0;
    padding: 0px 20px;
    .head {
      display: flex;
      justify-content: space-between;
      .logo {
        width: 27%;
        img {
          width: 100%;
        }
      }
      .center {
        margin-top: 6px;
        color: #eee;
        font-size: 20px;
      }
      .icon {
        color: #eee;
        font-size: 33px;
      }
    }
    .body {
      padding: 17px;
      .item {
        display: flex;
        margin-top: 10px;
        border-bottom: 0.5px solid #4e7a9a;
        i {
          margin-top: 5px !important;
          color: $SecMainColor;
        }
        .links {
          color: #eee;
          text-align: right;
          margin-right: 20px;
          margin-bottom: 15px;
        }
      }
    }
    .footer {
      .myBtn {
        margin: 30px 0 20px;
      }
      .social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 15px;
        a {
          color: #eee;
          margin-left: 15px;
          font-size: 30px;
        }
      }
      .copy {
        color: #eee;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  body[dir="ltr"] {
    .mobNav {
      .mobRight {
        .icon {
          font-size: 50px;
          color: $DarkBlueColor;
        }
      }
    }

    .mobBar {
      .head {
        .center {
          margin-top: 6px;
          color: #eee;
          font-size: 25px;
        }
        .icon {
          color: #eee;
          font-size: 53px;
        }
      }
      .body {
        padding: 25px;
        .item {
          margin-top: 20px;
          i {
            margin-top: 10px;
          }
          .links {
            margin-bottom: 20px;
            font-size: 23px;
          }
        }
      }
      .footer {
        margin-top: 20px;
        .myBtn {
          margin: 30px 0 20px;
        }
        .social {
          margin: 35px 15px;
          a {
            font-size: 40px;
          }
        }
        .copy {
          font-size: 18px;
        }
      }
    }
  }

  body[dir="rtl"] {
    .mobNav {
      .mobRight {
        .icon {
          font-size: 60px;
        }
      }
    }
    .mobBar {
      .head {
        .center {
          margin-top: 6px;
          color: #eee;
          font-size: 25px;
        }
        .icon {
          color: #eee;
          font-size: 53px;
        }
      }
      .body {
        padding: 25px;
        .item {
          margin-top: 20px;
          font-size: 26px !important;
          i {
            margin-top: 10px;
          }
          .links {
            margin-bottom: 20px;
            font-size: 23px;
          }
        }
      }
      .footer {
        margin-top: 20px;
        .myBtn {
          margin: 30px 0 20px;
        }
        .social {
          margin: 35px 15px;
          a {
            font-size: 40px;
          }
        }
        .copy {
          font-size: 18px;
        }
      }
    }
  }
}
