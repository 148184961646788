.shapeTwo .swiper {
  min-height: 100%;
  padding: 50px;
  margin-top: 40px;
}
.shapeTwo .swiper .swiper-slide {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #ffffff;
  width: 490px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: auto !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.shapeTwo .swiper .swiper-slide:hover {
  background-color: #1c476c;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.shapeTwo .swiper .swiper-slide img {
  width: 40%;
  height: 40%;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.shapeTwo .swiper .swiper-slide h4 {
  color: #1c476c;
  font-size: 18px;
  padding: 20px 0;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}

.shapeTwo .swiper .swiper-slide:hover h4 {
  color: #eee;
}
.shapeTwo .swiper .swiper-slide .Btony {
  margin: 10px 20px;
  height: 50px;
  width: 150px;
  background-color: #e85530;
  border: 1px solid #e85530;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.shapeTwo .swiper .swiper-slide .Btony i {
margin-left:10px;
}
.shapeTwo .swiper .swiper-slide .Btony:hover {
  transform: translate(0px, 10px);
  -webkit-transform: translate(0px, 10px);
  -moz-transform: translate(0px, 10px);
  -ms-transform: translate(0px, 10px);
  -o-transform: translate(0px, 10px);
}
.shapeTwo
  .swiper-horizontal
  > .shapeTwo
  .swiper-pagination-bullets
  .swiper-pagination-bullet,
.shapeTwo
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #e85530;
  top:65%;
}

.shapeTwo .swiper-button-next  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-right: 20px;
  color: #e85530;
  display: block;
}

.shapeTwo .swiper-button-next::after,.shapeTwo .swiper-button-prev::after  {
  display: none;
}
.shapeTwo .swiper-button-prev  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  rotate: 180deg;
  margin-left: 10px;
  color: #e85530;
  display: block;
}
.shapeTwo .swiper-pagination-bullet-active {
  background-color:#e85530 ;
  height: 13px;
  width: 13px;
  }



body[dir="rtl"]  .shapeTwo .swiper-button-next  {
background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
margin-left: 20px;
rotate: 180deg;
}

body[dir="rtl"]   .shapeTwo .swiper-button-prev  {
   background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg'); 
  rotate: 0deg; 
  margin-right: 10px;
}





body[dir="rtl"] .shapeTwo .swiper {
  height: auto;
  padding: 50px;
  margin-top: 40px;
}

body[dir="rtl"] .shapeTwo .swiper .swiper-slide {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #ffffff;
  width: 490px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: auto !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

body[dir="rtl"] .shapeTwo .swiper .swiper-slide:hover {
  background-color: #1c476c;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

body[dir="rtl"] .shapeTwo .swiper .swiper-slide img {
  width: 40%;
  height: 40%;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

body[dir="rtl"] .shapeTwo .swiper .swiper-slide h4 {
  color: #1c476c;
  font-size: 18px;
  padding: 20px 0;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}
body[dir="rtl"] .shapeTwo .swiper .swiper-slide:hover h4 {
  color: #eee;
}
body[dir="rtl"] .shapeTwo .swiper .swiper-slide .Btony {
  margin: 10px 20px;
  height: 50px;
  width: 150px;
  background-color: #e85530;
  border: 1px solid #e85530;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  letter-spacing: 0.3px;
}
body[dir="rtl"] .shapeTwo .swiper .swiper-slide .Btony i {
  margin-right:15px;
  }
body[dir="rtl"] .shapeTwo .swiper .swiper-slide .Btony:hover {
  transform: translate(0px, 10px);
  -webkit-transform: translate(0px, 10px);
  -moz-transform: translate(0px, 10px);
  -ms-transform: translate(0px, 10px);
  -o-transform: translate(0px, 10px);
}

/* media */
@media screen and (max-width: 767px) {
  .shapeTwo .swiper {
    padding: 50px 0;
    min-height: 50% !important;
  }
  .shapeTwo .swiper-container {
    width: 768px !important;
  }
  .shapeTwo .swiper .swiper-slide {
    width: 330px !important;
    margin-left: 10px !important;
  }
  body[dir="rtl"] .shapeTwo .swiper {
    padding: 50px 0;
  }
  body[dir="rtl"] .shapeTwo .swiper-container {
    width: 768px !important;
  }
  body[dir="rtl"] .shapeTwo .swiper .swiper-slide {
    width: 330px !important;
    margin-right: 10px !important;
  }
  body[dir="rtl"] .shapeTwo .swiper-pagination-bullets,
.shapeTwo .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:93% !important;
}
.shapeTwo .swiper-pagination-bullets,
.shapeTwo .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:93% !important;
}

.shapeTwo .swiper-button-next,.shapeTwo .swiper-button-prev{
  display: none;
}
body[dir="rtl"] .shapeTwo .swiper-button-next,.shapeTwo .swiper-button-prev{
  display: none;
}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* .shapeTwo .swiper {
    padding: 50px 0 !important;
  } */
  .shapeTwo .swiper-container {
    width: 991px !important;
  }
  .shapeTwo .swiper .swiper-slide {
    width: 630px !important;
    margin: 0 15px !important;
  }
  .shapeTwo .swiper-button-next,.shapeTwo .swiper-button-prev{
    display: none;
  }

  body[dir="rtl"] .shapeTwo .swiper-container {
    width: 991px !important;
  }
  body[dir="rtl"] .shapeTwo .swiper .swiper-slide {
    width: 630px !important;
    margin: 0 10px !important;
  }
 
body[dir="rtl"] .shapeTwo .swiper-pagination-bullets,
.shapeTwo .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:97% !important;
}
.shapeTwo .swiper-pagination-bullets,
.shapeTwo .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:97% !important;
}
body[dir="rtl"] .shapeTwo .swiper-button-next,.shapeTwo .swiper-button-prev{
  display: none;
}
}
