@import "../../../index.scss";

body[dir="ltr"] {
  .topBar {
    position: absolute;
    z-index: 99999;
    top: 3px;
    left: 5%;
    width: 90%;
    height: 30px;
    margin: 5px auto 0;
    display: flex;
    justify-content: space-between;
    color: $textColor;
    .left {
      padding: 5px;
      span {
        font-size: 20px;
      }
      i {
        margin-right: 10px;
        font-size: 27px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-right: 22px !important;
        position: relative;
        font-size: 14px;
        &::after {
          content: "";
          position: absolute;
          background-color: $DarkBlueColor;
          height: 23px;
          width: 1.5px;
          top: 0px;
          right: -12px;
        }
      }
      .last {
        &::after {
          content: "";
          position: absolute;
          background-color: transparent;
          height: 23px;
          width: 1.5px;
          top: 0px;
          right: -9px;
        }
      }
    }
  }
}

body[dir="rtl"] {
  .topBar {
    position: absolute;
    z-index: 99999;
    top: 3px;
    left: 5%;
    width: 90%;
    height: 30px;
    margin: 5px auto 0;
    display: flex;
    justify-content: space-between;
    color: $textColor;

    .left {
      padding: 5px;
      span {
        font-size: 20px;
        margin-right: 15px;
        font-family: "arabic" !important;
      }
      i {
        margin-right: 10px;
        font-size: 27px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-right: 22px !important;
        position: relative;
        font-size: 16px;
        font-family: "arabic" !important;
        &::after {
          content: "";
          position: absolute;
          background-color: $DarkBlueColor;
          height: 23px;
          width: 1.5px;
          top: 0px;
          right: -12px;
        }
      }
      .last,
      .lastar {
        &::after {
          content: "";
          position: absolute;
          background-color: transparent;
          height: 23px;
          width: 1.5px;
          top: 0px;
          right: -9px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .topBar {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .topBar {
    display: none;
  }
}
