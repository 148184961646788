@import '../../../index.scss';


.main{
    padding: 50px 0 0;
    width: 100%;
    min-height: 5vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        margin-bottom: 20px;
        h2{
            font-size: 38px;
            color: $DarkBlueColor;
        }
    }
    .content{
        min-height: 10vh;
        padding: 40px 0 0;
        background-color: #F2F2F2;
        .title{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            h3{
                font-size: 35px;
                color: $DarkBlueColor;
            }
        }
    }
}

body[dir='rtl']{
    .main{

        .head{

            h2{
                font-size: 38px;
                font-family: "arabic";
            }
        }
        .content{
            .title{
                h3{
                    font-family: "arabic";
                }
            }
        }
    }  
}



@media screen and (max-width: 767px) {
    .main{
        padding: 50px 0 0;
        width: 100%;
        min-height: 5vh;
        .head{
            h2{
                font-size: 25px;
                text-align: center;
            }
        }
        .content{
            min-height:5vh;
            h3{
                text-align: center;
            }
        }
    }
    body[dir='rtl']{
        .main{
            padding: 50px 0 0;
            width: 100%;
            min-height: 5vh;
            .head{
                h2{
                    font-size: 25px;
                    text-align: center;
                }
            }
            .content{
                min-height:5vh;
                h3{
                    text-align: center;
                }
            }
        }
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .main{
        padding: 50px 0 0;
        width: 100%;
        min-height: 5vh;
        .head{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            h2{
                font-size: 45px;
                color: $DarkBlueColor;
                text-align: center;
            }
        }
        .content{
            min-height: 3vh;
            h3{
                text-align: center;
            }
        }
    }
  }