@import "../../../index.scss";


.main{
    padding: 60px 0 ;
    background-color: #F2F2F2;
    width: 100%;
    min-height: 70vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        h2{
            font-size: 45px;
            color: $DarkBlueColor;
        }
    }
    

}



@media screen and (max-width: 767px) {
    .main{
        padding: 0;
        min-height: 60vh;
    }
}


@media screen and (min-width:768px) and (max-width:991px){
    .main{
        min-height: 50vh;
        }
        
    
    
}