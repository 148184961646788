@import "../../../index.scss";

.banner {
 position: relative;
 width: 100%;
 height: 60vh;
 margin: 0;
 background-size: cover;
 background-position: center;
.overlay{
    position: absolute;
    background-color: rgb(28, 71, 108, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .bannerContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
            margin-top: 50px;
            font-size: 35px;
            text-transform: uppercase;
            letter-spacing: 10px;
            color: #eeeeee;
            font-family: "title";
            text-align: center;
        }
    }
    }
}

body[dir="rtl"] {
    .banner {

       .overlay{
           .bannerContainer{
               h3{
                   margin-top: 50px;
                   font-size: 80px;
                   letter-spacing: 0px;
                   font-family: "arabic";
               }
           }
           }
       }  
}
@media screen and (max-width:767px){
    .banner {
        .overlay{

            h3{
                font-size: 35px;
            }
        }
       }
       }
       
       @media screen and (min-width: 768px) and (max-width: 991px) {
        .banner {
            .overlay{
    
                h3{
                    font-size: 55px;
                }
            }
           }
       }