@import '../../../index.scss';

.mainModalSmall{
    .myModalSmall {
        background:linear-gradient(90deg,$mainColor,$SecMainColor) !important;
        border: 1px solid $mainColor !important;
        height: 50px !important;
        width: 250px;
        @include borderRadius(30px);
        i{
            margin-left: 10px;
        } 
    }
    .modalDiaogSmall{
        .modalContentSmall{

            .myBtn{
                background-color: $DarkBlueColor !important;
                @include borderRadius(30px);
                width: 120px;
                i{
                 margin-left: 10px;
                 color: $mainColor !important;
                }
             }
        }
    }
}


body[dir='rtl']{
    .mainModalSmall{
        .myModalSmall {
            background:linear-gradient(90deg,$mainColor,$SecMainColor) !important;
            border: 1px solid $mainColor !important;
            height: 50px !important;
            width: 250px;
            @include borderRadius(30px);
            i{
                margin-right: 20px;
            } 
        }
        .modalDiaogSmall{
            .modalContentSmall{
                .modalHeaderSmall{
                    .btnCloseSmall{
                        margin: 0 !important;
                    }
                }
               .myBtn{
                    background-color: $DarkBlueColor !important;
                    @include borderRadius(30px);
                    width: 120px;
                    i{
                     margin-right: 20px;
                     color: $mainColor !important;
                    }
                 }
            }
        }
    }  
}