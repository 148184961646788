@import "../../../index.scss";

.main{
    padding: 60px 0 ;
    background-color: #F2F2F2;
    width: 100%;
    height: 100vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        h2{
            font-size: 45px;
            color: $DarkBlueColor;
        }
    }
    .mainModal{
        .modalBody{
         --bs-modal-width: 75%;
         .modalContent{
                height: fit-content !important;
            .modalInfo{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
            .modalClosed{
                color:$SecMainColor!important;
                font-size: 35px;
                cursor: pointer;
            }
            .modalPara{
                color: $DarkBlueColor;
                text-align: left;
                h4{
                    font-size: 30px;
                    margin: 20px 0;
                }
                h6{
                    font-size: 15px;
                    margin: 10px 0; 
                    font-weight: 600; 
                }
                span{
                    font-size: 16px;
                    margin: 25px 0 !important;   
                }
                h5{
                    font-size: 16px;
                    margin: 25px 0 !important;
                    font-weight: 400;  
                    line-height: 25px !important;   
                }
            }
            }
            }
         }
    }  

}

body[dir='rtl']{
    .main{

        .head{
            h2{
                font-family: "arabic";
            }
        }
        .mainModal{
            .modalBody{
             .modalContent{
                .modalInfo{
                .modalPara{
                    text-align: right;
                    h4{
                        font-family: "arabic";
                    }
                    h6{
                        font-family: "arabic";
                    }
                    span{
                        font-family: "arabic";  
                    }
                    h5{
                        font-family: "arabic"; 
                    }
                }
                }
                }
             }
        }  
    
    }
}



@media screen and (max-width:767px){
    .main{
        padding: 30px 0 ;
        background-color: #F2F2F2;
        width: 100%;
        height: 70vh;
        margin-top: 0px;
        .head{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            h2{
                font-size: 30px;
                color: $DarkBlueColor;
            }
        }
        .mainModal{
            .modalBody{
             .modalContent{
                .modalInfo{
                .modalPara{
                    h4{
                        font-size: 20px;
                        margin: 20px 0;
                    }              
                }
                }
                }
             }
        } 
        
    
    }
    
  }


  @media screen and (min-width:768px) and (max-width:991px){
    .main{
        padding: 60px 0 ;
        background-color: #F2F2F2;
        width: 100%;
        height: 60vh;
        margin-top: 80px;
        .head{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            h2{
                font-size: 40px;
                color: $DarkBlueColor;
            }
        }
        
    
    }
  }