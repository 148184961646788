.PhotoImg .swiper {
  width: 100%;
  min-height: 100%;
  padding: 30px 0;
}

.PhotoImg .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PhotoImg .swiper-slide img {
  display: block;
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.PhotoImg .swiper-horizontal > .PhotoImg .swiper-pagination-bullets,
.PhotoImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}
.PhotoImg button {
  border-radius: 30px;
  margin: 0 20px 20px 0;
  height: 60px;
  width: 180px;
  background-color: #e85530;
  border: 1px solid #e85530;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}
.PhotoImg button i {
  margin-left: 15px;
}

.PhotoImg h3 {
  color: #1c476c;
  text-align: center;
  padding: 20px 0;
  font-size: 35px;
}

body[dir="rtl"] .PhotoImg button i {
  margin-right: 15px;
}
.PhotoImg button:hover {
  transform: translate(0px, 10px);
  -webkit-transform: translate(0px, 10px);
  -moz-transform: translate(0px, 10px);
  -ms-transform: translate(0px, 10px);
  -o-transform: translate(0px, 10px);
  background-color: #e85530;
  border: 1px solid #e85530;
}

@media screen and (max-width: 767px) {
  .PhotoImg .swiper {
    width: 100%;
    min-height: 100%;
    margin-bottom: 30px;
  }
  .PhotoImg .swiper-horizontal > .PhotoImg .swiper-pagination-bullets,
  .PhotoImg .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 96% !important;
    display: block;
  }
  .PhotoImg .swiper-pagination-bullet-active {
    background-color: #e85530;
    height: 11px;
    width: 11px;
  }

  body[dir="rtl"] .PhotoImg .swiper-slide img {
    width: 90% !important;
    margin-left: 15px;

  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .PhotoImg .swiper {
    width: 100%;
    min-height: 100%;
    margin-bottom: 30px;
  }
  .PhotoImg .swiper-horizontal > .PhotoImg .swiper-pagination-bullets,
  .PhotoImg .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 96.3% !important;
    display: block;
  }
  .PhotoImg .swiper-pagination-bullet-active {
    background-color: #e85530;
    height: 11px;
    width: 11px;
  }
}
