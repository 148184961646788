.shapeSeven .swiper {
    min-height: 52vh;
    padding: 30px;
    margin-top: 40px;
    margin-left: auto;
  text-align: center;
  }

  .shapeSeven .swiper .swiper-slide {
    margin: 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #fff; */
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 20rem !important;
    height: 20rem !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
    -moz-transition: 1s all ease;
    -ms-transition: 1s all ease;
    -o-transition: 1s all ease;
    cursor: pointer;
}

.shapeSeven .swiper .swiper-slide:hover {
    background: #fff;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  .shapeSeven .swiper .swiper-slide .img {

      width: 10rem;
      height: 10rem;
  }
  .shapeSeven .swiper .swiper-slide .img img {
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
   max-width: 100%;
}


.shapeSeven .swiper .swiper-slide .title h3 {
    text-align: center;
    padding: 20px 15px 10px;
    color: #e85530;
    font-weight: 600;
  }
  .shapeSeven .swiper .swiper-slide .title h4{
color: #1c476c;
  }
  .swiper-pagination-bullet-active {
    background-color:#e85530 ;
    }



    .shapeSeven .swiper-horizontal > .shapeSeven .swiper-pagination-bullets,
.shapeSeven .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:95% !important;
}

.shapeSeven .swiper-button-next  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-right: 20px;
    color: #e85530;
    top: 207px;
}

.shapeSeven .swiper-button-next::after,.shapeSeven .swiper-button-prev::after  {
    display: none;
  }
  .shapeSeven .swiper-button-prev  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    rotate: 180deg;
    margin-left: 10px;
    color: #e85530;
    top: 207px;
}
 body[dir="rtl"]  .shapeSeven .swiper-button-next  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  margin-left: 20px;
  rotate: 180deg;
}

body[dir="rtl"] .shapeSeven .swiper-button-prev  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  rotate: 0deg;
  margin-right: 10px;
} 


body[dir="rtl"] .shapeSeven .swiper .swiper-slide .title h3 {
    font-family: "arabic";
    font-weight: 400;
  }
  body[dir="rtl"]  .shapeSeven .swiper .swiper-slide .title h4{
color: #1c476c;
font-family: "arabic";
  }




@media screen and (max-width: 767px) {
    .shapeSeven .swiper {
        min-height: 100vh;
        padding:0 30px 30px 30px !important;
        margin-top: 20px;
        margin-left: auto;
        text-align: center; 
      }
      .shapeSeven .swiper .swiper-slide {
        margin: 40px 20px;
        display: flex;
        justify-content: center !important;
        align-items: center;
        width: 16rem !important;
        height: 16rem !important;
      }
      .shapeSeven .swiper-pagination-bullets.swiper-pagination-horizontal {
        top: 49% !important;
      }
      .shapeSeven .swiper-button-next,
      .shapeSeven .swiper-button-prev {
        display: none;
      }
       .shapeSeven .swiper .swiper-slide .title h3 {
         font-size: 19px;
 }
}


@media screen and (min-width:768px) and (max-width:991px){
    .shapeSeven .swiper {
        min-height: 60vh;
        padding: 30px 0 !important;
        margin-top: 40px;
        margin-left: auto;
        text-align: center;
      }
      .shapeSeven .swiper .swiper-slide {
        width: 22rem !important;
        height: 22rem !important;
        margin: 40px 10px !important;
        padding-left: 0px !important;
        
      }
      .shapeSeven .swiper .swiper-slide .img {
        margin-top: 0px;
      }
      .shapeSeven .swiper .swiper-slide .title h3 {
        font-size: 18px;

    }
    .shapeSeven .swiper-button-next,
    .shapeSeven .swiper-button-prev {
      display: none !important;
    }
    .shapeSeven .swiper-horizontal > .shapeSeven .swiper-pagination-bullets,
    .shapeSeven .swiper-pagination-bullets.swiper-pagination-horizontal {
      top:70% !important;
    }
}