@import "../../../index.scss";

.mainEvent {
  margin-top: 60px;
  min-height: 100vh;
  .eventInfo {
    .eventTabs {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 30px;
      .right {
        width: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        .navEvent {
          border: 1px solid #d0d7dd !important;
          width: 100%;
          transition: 1s all ease;
          -webkit-transition: 1s all ease;
          -moz-transition: 1s all ease;
          -ms-transition: 1s all ease;
          -o-transition: 1s all ease;
          color: $DarkBlueColor;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
      .left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .navEvent {
          border: 1px solid #d0d7dd !important;
          width: 100%;
          transition: 1s all ease;
          -webkit-transition: 1s all ease;
          -moz-transition: 1s all ease;
          -ms-transition: 1s all ease;
          -o-transition: 1s all ease;
          color: $DarkBlueColor;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
    }
    .tabContent {
      padding-top: 40px;
      p {
        color: $DarkBlueColor;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mainEvent {
    margin-top: 60px;
    min-height: 100vh;
    .eventInfo {
      .eventTabs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 24px;
        .right {
          width: 50% !important;
          display: flex;
          justify-content: center;
          align-items: center;
          .navEvent {
            border: 1px solid #d0d7dd !important;
            width: 100%;
            transition: 1s all ease;
            -webkit-transition: 1s all ease;
            -moz-transition: 1s all ease;
            -ms-transition: 1s all ease;
            -o-transition: 1s all ease;
            color: $DarkBlueColor;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
        .left {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .navEvent {
            border: 1px solid #d0d7dd !important;
            width: 100%;
            transition: 1s all ease;
            -webkit-transition: 1s all ease;
            -moz-transition: 1s all ease;
            -ms-transition: 1s all ease;
            -o-transition: 1s all ease;
            color: $DarkBlueColor;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
      }
      .tabContent {
        padding-top: 40px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainEvent {
    margin-top: 60px;
    min-height: 65vh;
  }
}
