.shapeOne .swiper {
    min-height: 70vh;
    padding: 30px;
    margin-top: 40px;
    margin-left: auto;
    display: flex;
   justify-content: space-around !important;
  }
  
  
  
  .shapeOne .swiper .swiper-slide {
    margin: 40px 30px ;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fbfbfb;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 20rem !important;
    height: 20rem !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
    -moz-transition: 1s all ease;
    -ms-transition: 1s all ease;
    -o-transition: 1s all ease;
  }
  .shapeOne .swiper .swiper-slide:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  .shapeOne .swiper .swiper-slide .shapeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 16rem !important;
    width: 16rem !important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: linear-gradient(180deg, #ee6d45 50%, #fff 0) !important;
    box-shadow: 0 0 7px 2px #ddd !important;
  }
  
  .shapeOne .swiper .swiper-slide .counter {
    font-size: 28px;
    color: #eeeeee;
    margin-top: -10px;
    text-align: center;
  }
  .shapeOne .swiper .swiper-slide .counter  h6{
    margin-top: -25px;
    text-align: center;
    font-size: 23px;
  }
  .shapeOne .swiper .swiper-slide .title {
    text-align: center;
  }
  .shapeOne .swiper .swiper-slide .title h3 {
    font-size: 18px;
    width: 200px;
    text-align: center;
    padding: 0 15px;
    transform: translate(0px, 35px);
    -webkit-transform: translate(0px, 35px);
    -moz-transform: translate(0px, 35px);
    -ms-transform: translate(0px, 35px);
    -o-transform: translate(0px, 35px);
    color: #1c476c;
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
    -moz-transition: 1s all ease;
    -ms-transition: 1s all ease;
    -o-transition: 1s all ease;
  }
  
  .shapeOne .swiper .swiper-slide:hover h3 {
    font-weight: 700;
  }
  .shapeOne .swiper-pagination-bullet-active {
  background-color:#e85530 ;
  height: 13px;
  width: 13px;
  }
  

  .shapeOne .swiper-button-next  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-right: 20px;
    color: #e85530;
}

.shapeOne .swiper-button-next::after,.shapeOne .swiper-button-prev::after  {
    display: none;
  }
  .shapeOne .swiper-button-prev  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    rotate: 180deg;
    margin-left: 10px;
    color: #e85530;
}
body[dir="rtl"]  .shapeOne .swiper-button-next  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  margin-left: 20px;
  rotate: 180deg;
}

body[dir="rtl"]   .shapeOne .swiper-button-prev  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  rotate: 0deg;
  margin-right: 10px;

}




  body[dir="rtl"] .shapeOne .swiper-button-next,
  .shapeOne .swiper-button-prev{
    left: -2px;
  }
  .shapeOne .swiper-horizontal > .shapeOne .swiper-pagination-bullets,
  .shapeOne .swiper-pagination-bullets.swiper-pagination-horizontal {
    top:90% !important;
  }
  


  @media screen and (max-width:767px){
    .shapeOne .swiper {
        padding: 30px;
        min-height: 50vh;
      }
      
    .shapeOne .swiper .swiper-slide {
        margin: 40px 0px 40px 20px;
        width: 16rem !important;
        height: 16rem !important; 
      }
     
      .shapeOne .swiper .swiper-slide .shapeContainer {
        height: 14rem !important;
        width: 14rem !important;
      }
      .shapeOne .swiper-button-next,
      .shapeOne .swiper-button-prev {
        display: none;
      }
      .shapeOne .swiper-pagination-bullets.swiper-pagination-horizontal {
        top: 91% !important;
      }

      body[dir="rtl"] .shapeOne .swiper .swiper-slide{
        margin: 40px 20px 40px 0px;
      }
  }


  @media screen and (min-width:768px) and (max-width:991px){
    .shapeOne .swiper {
        padding: 50px 0px !important;
        margin-top: 0px;
        margin-left: auto;
        text-align: center;
        min-height: 55vh;
      }
    .shapeOne .swiper .swiper-slide {
        width: 26rem !important;
        height: 26rem !important;
        margin: 40px 0px;
      }
      .shapeOne .swiper .swiper-slide .shapeContainer {
        width: 21rem !important;
        height: 21rem !important;
      }
      .shapeOne .swiper .swiper-slide .counter {
        font-size: 22px;
      }
      .shapeOne .swiper .swiper-slide .title h3 {
        font-size: 19px;
        width: 190px;
        transform: translate(0px, 63px);
        -webkit-transform: translate(0px, 63px);
        -moz-transform: translate(0px, 63px);
        -ms-transform: translate(0px, 63px);
        -o-transform: translate(0px, 63px);
}
      .shapeOne .swiper-button-next,
      .shapeOne .swiper-button-prev {
        display: none !important;
      }
      .shapeOne .swiper-horizontal > .shapeOne .swiper-pagination-bullets,
      .shapeOne .swiper-pagination-bullets.swiper-pagination-horizontal {
        top:87% !important;
      }
      .shapeOne .swiper .swiper-slide .counter  h6{
        margin-top: -30px;
        text-align: center;
        font-size: 23px;
      }
}