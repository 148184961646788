@import '../../../index.scss';




@media screen and (min-width:1280px){
    .mainModal{
        .myModal {
            background:linear-gradient(90deg,$mainColor,$SecMainColor) !important;
            border: 1px solid $mainColor !important;
            height: 50px !important;
            font-size: 12px;
            @include borderRadius(15px);
        
        }
        .modalBody{
            .modalDiaog{
                margin-right: auto !important;
                margin-left: auto !important;
            }
            .modalContent{
                width:700px !important;
                height: 600px !important;
                .modalFooter{
                    border-top: 1px solid transparent !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center;
                    margin-bottom: 10px !important;
                    .myBtn{
                       background-color: $DarkBlueColor !important;
                       @include borderRadius(30px);
                       width: 120px;
                       i{
                        margin-left: 10px;
                        color: $mainColor !important;
                       }
                    }
                }
                .modalHeader{
                    .head{
                        color:$DarkBlueColor !important;
                    }
                    .btnClose{
                         background-color:$mainColor!important;
                    }
                }
            }
         }
    }

    body[dir='rtl']{
        .mainModal{
            .myModal {
                background:linear-gradient(90deg,$mainColor,$SecMainColor) !important;
                border: 1px solid $mainColor !important;
                height: 50px !important;
                font-size: 12px;
                @include borderRadius(15px);
                width: 190px;
                font-size: 16px;
                font-family: "arabic" !important;
                i{
                    margin-right: 27px;
                }
            }
            .modalBody{
                .modalDiaog{
                    margin-right: auto !important;
                    margin-left: auto !important;
                }
                .modalContent{
                    width:700px !important;
                    height: 600px !important;
                    .modalHeader{
                        display: flex !important;
                        justify-content: space-between !important;
                        .head{
                            color:$DarkBlueColor !important;
                        }
                        .btnClose{
                             background-color:$mainColor!important;
                            margin: 0 !important;
                        }
                    }
                    .modalFooter{
                        border-top: 1px solid transparent !important;
                        display: flex;
                        align-items: center !important;
                        justify-content: center;
                        margin-bottom: 10px !important;
                        .myBtn{
                           background-color: $DarkBlueColor !important;
                           @include borderRadius(30px);
                           width: 130px;
                           font-size: 18px;
                           i{
                            margin-right: 25px;
                            color: $mainColor !important;
                           }
                        }
                    }
                 
                }
             }
        }
    }
  }