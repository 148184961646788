@import "../../../index.scss";

.mainProjects {
  min-height: 70vh;
  padding: 60px 0 40px;
  .head {
    border-bottom: 0.5px solid rgb(28, 71, 108, 0.2);
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 45px;
      color: $DarkBlueColor;
    }
    .two {
      @include borderRadius(30px);
      height: 60px;
      width: 180px;
      margin: 0 20px 20px 0;
      background-color: $textColor;
      color: $SecMainColor;
      border: 1px solid $DarkBlueColor;
      @include transition();
      transform: translate(0px, 0px);
      -webkit-transform: translate(0px, 0px);
      -moz-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      -o-transform: translate(0px, 0px);
      &:hover {
        transform: translate(0px, 10px);
        -webkit-transform: translate(0px, 10px);
        -moz-transform: translate(0px, 10px);
        -ms-transform: translate(0px, 10px);
        -o-transform: translate(0px, 10px);
      }
      i {
        margin-left: 10px;
      }
    }
  }
  .content {
    margin: 60px 0;
    // background-image: url("../../../public/images/latest-project-bg.0ffdb66f.png");
    height: 500px;
    background-position: center;
    background-size: cover;
    @include borderRadius(30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .caption {
        width: 80%;
      display: flex;
      margin: auto;
      h3 {
        margin: 20px 40px;
        font-size: 3rem;
        color: $textColor;
        width: 30%;
      }
      .myBtn {
        margin-left: 60px;
        margin-top: 140px;
        @include borderRadius(30px);
        height: 50px;
        width: 170px;
        background-color: $SecMainColor;
        color: $textColor;
        border: 1px solid $SecMainColor;
        @include transition();
        &:hover {
          transform: translate(0px, 10px);
          -webkit-transform: translate(0px, 10px);
          -moz-transform: translate(0px, 10px);
          -ms-transform: translate(0px, 10px);
          -o-transform: translate(0px, 10px);
        }
        i {
          margin-left: 10px;
        }
      }
    }
  }
  .divyflex {
    display: none;
  }
}

body[dir="rtl"] {
    .mainProjects {
    
        .head {
          .two {
            i {
              margin-right: 10px;
            }
          }
        }
        .content {
          .caption {
            h3 {
              margin: 20px 40px;
              font-size: 3rem;
              color: $textColor;
              width: 30%;
            }
            .myBtn {
              margin-right: 60px;
              margin-top: 90px;
            
              i {
                margin-right: 10px;
              }
            }
          }
        }
        .divyflex {
          display: none;
        }
      }
}



@media screen and (max-width: 767px) {
  .mainProjects {
    min-height: 50vh;
    padding: 60px 0 40px;
    .head {
      justify-content: center;
      h2{
        font-size: 30px;
       text-align: center;
       margin-bottom: 10px;
      }
      .two {
        display: none;
      }
    }
    .content {
      width: 80%;
      margin: 50px auto;
      height: 300px;
      .caption {
        display: flex;
        h3 {
          margin: 20px 40px;
          font-size: 1.7rem;
          color: $textColor;
          width: 30%;
        }
        .myBtn {
          display: none;
        }
      }
    }
    .divyflex {
      display: block;
      .btnflex {
        @include borderRadius(30px);
        margin: 0 20px 20px 0;
        height: 60px;
        width: 200px;
        background-color: $DarkBlueColor;
        border: 1px solid $DarkBlueColor;
        @include transition();
        transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
        font-size: 20px;
        i {
          color: $SecMainColor;
        }
        &:hover {
          transform: translate(0px, 10px);
          -webkit-transform: translate(0px, 10px);
          -moz-transform: translate(0px, 10px);
          -ms-transform: translate(0px, 10px);
          -o-transform: translate(0px, 10px);
        }
        i {
          margin-left: 5px;
        }
      }
    }
  }

  body[dir="rtl"]{
    .mainProjects {
        font-family: "arabic";
        .head {
          text-align: center;
          h2 {
            font-size: 35px;
            font-family: "arabic";
          }
          .two {
            display: none;
          }
        }
        .content {
            width: 80%;
            margin: 50px auto;
            height: 300px;
          .caption {
            display: flex;
           h3 {
          margin: 20px 10px;
          font-size: 2.1rem;
          width: 90%;
          text-align: center;
          font-family: "arabic";
         }
            .myBtn {
              display: none;
            }
          }
        }
        .divyflex {
          display: block;
          .btnflex {
            @include borderRadius(30px);
            margin: 0 20px 20px 0;
            height: 60px;
            width: 180px;
            background-color: $DarkBlueColor;
            border: 1px solid $DarkBlueColor;
            @include transition();
            transform: translate(0px, 0px);
            -webkit-transform: translate(0px, 0px);
            -moz-transform: translate(0px, 0px);
            -ms-transform: translate(0px, 0px);
            -o-transform: translate(0px, 0px);
            font-size: 20px;
            i {
              color: $SecMainColor;
            }
           
            i {
              margin-right: 10px;
            }
          }
        }
      }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainProjects {
    min-height: 50vh;
    padding: 60px 0 40px;
    .head {
      h2{
        font-size: 40px;
       text-align: center;
       margin-bottom: 10px;
      }
      .two {
        display: none;
      }
    }
    .content {
      width: 90%;
      margin: 50px auto;
      height: 300px;
      .caption {
        display: flex;
        h3 {
          margin: 20px 40px;
          font-size: 2.8rem;
          color: $textColor;
          width: 90%;
        }
        .myBtn {
          display: none;
        }
      }
    }
    .divyflex {
      display: block;
      .btnflex {
        @include borderRadius(30px);
        margin: 0 20px 20px 0;
        height: 60px;
        width: 200px;
        background-color: $DarkBlueColor;
        border: 1px solid $DarkBlueColor;
        @include transition();
        transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
        font-size: 18px;
        i {
          color: $SecMainColor;
        }
        &:hover {
          transform: translate(0px, 10px);
          -webkit-transform: translate(0px, 10px);
          -moz-transform: translate(0px, 10px);
          -ms-transform: translate(0px, 10px);
          -o-transform: translate(0px, 10px);
        }
        i {
          margin-left: 10px;
        }
      }
    }
  }
  body[dir="rtl"]{
    .mainProjects {
        font-family: "arabic";
        .head {
          text-align: center;
          h2 {
            font-size: 35px;
            font-family: "arabic";
          }
          .two {
            display: none;
          }
        }
        .content {
          width: 90%;
          margin: 50px auto;
          height: 300px;
          .caption {
            display: flex;
            h3 {
              margin: 20px 40px;
              font-size: 2.8rem;
              color: $textColor;
              width: 90%;
            }
            .myBtn {
              display: none;
            }
          }
        }
        .divyflex {
          display: block;
          .btnflex {
            i {
              margin-right: 10px;
            }
          }
        }
      }
  }
}
