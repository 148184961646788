@import "../../../index.scss";


.cover{
    position: relative;
    width: 100%;
    height: 40vh;
    margin: 0;
    background-size: cover;
    background-position: center;
    .overlay{
        position: absolute;
        z-index: 99;
        background-color: rgba(18, 18, 19, 0.7);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        .caption{
            width: 85%;
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #eeeeee;
            text-align: center;
            h4{
             font-size: 35px;
            }
            .one {
                margin: 20px;
                height: 50px;
                width: 180px;
                background-color: $SecMainColor;
                border: 1px solid $SecMainColor;
                @include transition();
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  transform: translate(0px, 10px);
                  -webkit-transform: translate(0px, 10px);
                  -moz-transform: translate(0px, 10px);
                  -ms-transform: translate(0px, 10px);
                  -o-transform: translate(0px, 10px);
                }
                i {
                  margin-left: 10px;
                }
              }
              p{
                font-size: 15px;
                font-family: "calibri";
              }
        }
    }
}

body[dir='rtl']{
    .cover{
        .overlay{
            .caption{
                h4{
                 font-size: 35px;
                 font-family: "arabic";
                }
                .one {
                    font-family: "arabic";
                    i {
                        margin-right: 10px;
                      }
                  }
                  p{
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-family: "arabic";                  }
            }
        }
    }
}