@import "../../../index.scss";


.copy {
    background-color: #144773;
    padding:30px 0 30px 0;
    .copyContainer{  
        border-bottom: 1px solid rgb(238, 238, 238,0.3);
        .item{
            margin-bottom: 5px;
            h3{
                color: $textColor;
                font-size: 16px;
                text-align: center;
            }
            .social {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 25px 15px 0px;
                a {
                  color: #eee;
                  margin-left: 15px;
                  font-size: 35px;
                }
              }
        }
    }
}

.copyRight{
    background-color: #144773;
    padding:0 0 5px 0;
    color: $textColor;
    font-size: 12px;
}

body[dir='rtl']{
    .copy {
        font-family: "arabic";
    }
    .copyRight{
        p{
            font-family: "arabic"; 
            letter-spacing: 0.5px;
            font-size: 14px;
        }
        }
}

@media screen and (max-width: 767px) {
    .copy {
        .copyContainer{  
            border-bottom: 1px solid rgb(238, 238, 238,0.3);
            width: 90%;
            .item{
               text-align: center;
            }
        }
    }
    .copyRight{
       margin-top: -10px;
       text-align: center;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .copy {
        .copyContainer{  
            border-bottom: 1px solid rgb(238, 238, 238,0.3);
            width: 90%;
            .item{
               text-align: center;
            }
        }
    }
    .copyRight{
       margin-top: -10px;
       text-align: center;
    }
  }