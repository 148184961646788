.journeyImg .swiper {
    width: 100%;
    min-height: 100%;
    padding: 30px 0;
  }
  
  .journeyImg  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
}
  
  .journeyImg  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.journeyImg .swiper-horizontal > .journeyImg .swiper-pagination-bullets,
.journeyImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

@media screen and (max-width: 767px) {
 
.journeyImg .swiper {
    width: 100%;
    min-height: 100%;
    margin-bottom: 30px;
  }
.journeyImg .swiper-horizontal > .journeyImg .swiper-pagination-bullets,
.journeyImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:97% !important;
  display: block;
}
.swiper-pagination-bullet-active {
    background-color:#e85530 ;
    height: 11px;
    width: 11px;
    }
}


@media screen and (min-width:768px) and (max-width:991px){
    .journeyImg .swiper {
        width: 100%;
        min-height: 100%;
        margin-bottom: 30px;
      }
      .journeyImg .swiper-horizontal > .journeyImg .swiper-pagination-bullets,
.journeyImg .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:98.3% !important;
  display: block;
}
.swiper-pagination-bullet-active {
    background-color:#e85530 ;
    height: 11px;
    width: 11px;
    }
}