@import "../../../index.scss";


.mainFacts{
    padding: 60px 0 ;
    background-color: #F2F2F2;
    min-height: 70vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        h2{
            font-size: 45px;
            color: $DarkBlueColor;
        }
    }
    

}


@media screen and (max-width:767px){
    .mainFacts{
        padding: 60px 0 ;
        background-color: #F2F2F2;
        min-height: 50vh;
        .head{
            border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
            text-align: center;
            h2{
              font-size: 30px;
             text-align: center;
             margin-bottom: 10px;
            }
        }
        
    
    }
    
  }


  @media screen and (min-width:768px) and (max-width:991px){
    .mainFacts{
        padding: 60px 0 ;
        min-height: 45vh;
        .head{
      h2 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
        }
        
    
    }
  }

 
  