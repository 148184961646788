@import "../../../index.scss";

.main{
    padding: 50px 0 ;
    width: 100%;
    min-height: 100vh;
    .head{
        border-bottom: 0.5px solid rgb(28, 71, 108,0.2);
        h2{
            font-size: 45px;
            color: $DarkBlueColor;
        }
    }
    .picture{
        padding: 80px 0 0;
        img{
            @include borderRadius(20px);
            width: 100%;
        }
    }
    .content{
        padding: 80px 0 0;
        h6 {
            // margin: 20px 5px;
            font-weight: 500;
            color: #a3b0bd;
          }
          h4 {
            color: $DarkBlueColor;
            font-size: 35px;

          }
          p {
            color: $DarkBlueColor;
            font-size: 15px;
            font-family: "calibri";
          }
    }
}

body[dir="rtl"] {
    .main{
    
        .head{
            h2{
                font-family: "arabic";
            }
        }
        .content{
            padding: 80px 0 0;
            h6 {
                font-family: "arabic";
              }
              h4 {
                font-family: "arabic";
              }
              p {
                font-family: "arabic";
                text-align: 16px;
                font-weight: 400;
                letter-spacing: 0.3px;
                
              }
        }
    }
       
}



@media screen and (max-width: 767px) {
    .main{
      padding: 60px 0 ;
      min-height: 55vh;
      .head{
        h2{
            font-size: 30px;
           text-align: center;
           margin-bottom: 10px;
        }
    }
    }
    body[dir="rtl"] {
      .main{
        padding: 30px 0 ;
        min-height: 50vh;
        .head{
          h2{
          text-align: center;
          margin-bottom: 10px;
          }
      }
      .content{
        padding: 30px 0 0;
    }
      }
    }
  }
  


@media screen and (min-width: 768px) and (max-width: 991px) {
    .main{
        min-height: 30vh;
        padding: 30px 0 ;
        .head{
            h2{
                font-size: 30px;
               text-align: center;
               margin-bottom: 10px;
            }
        }
    }
}