.shapeSix .swiper {
    min-height: 100vh;
    padding: 30px;
    margin-top: 40px;
    margin-left: auto;
  text-align: center;
  }

  .shapeSix .swiper .swiper-slide {
    margin: 40px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 20rem !important;
    height: 20rem !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
    -moz-transition: 1s all ease;
    -ms-transition: 1s all ease;
    -o-transition: 1s all ease;
    cursor: pointer;
  }

  .shapeSix .swiper .swiper-slide:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  .shapeSix .swiper .swiper-slide .shapeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .shapeSix .swiper .swiper-slide .img {
    margin-top: -50px;
  }
  .shapeSix .swiper .swiper-slide .img img{
    width: 100%;
  }
  .shapeSix .swiper .swiper-slide .title h3 {
    font-size: 19px;
    width: 220px;
    text-align: center;
    padding: 0 15px;
    transform: translate(0px, 35px);
    -webkit-transform: translate(0px, 35px);
    -moz-transform: translate(0px, 35px);
    -ms-transform: translate(0px, 35px);
    -o-transform: translate(0px, 35px);
    color: #1c476c;
    font-family: "title";
  }

.swiper-pagination-bullet-active {
    background-color:#e85530 ;
    }
.shapeSix .swiper-horizontal > .shapeSix .swiper-pagination-bullets,
.shapeSix .swiper-pagination-bullets.swiper-pagination-horizontal {
  top:65% !important;
}

.shapeSix .swiper-button-next  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-right: 20px;
    color: #e85530;
    top: 267px;
}

.shapeSix .swiper-button-next::after,.shapeSix .swiper-button-prev::after  {
    display: none;
  }
  .shapeSix .swiper-button-prev  {
    background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    rotate: 180deg;
    margin-left: 10px;
    color: #e85530;
    top: 267px;
}
 body[dir="rtl"]  .shapeSix .swiper-button-next  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  margin-left: 20px;
  rotate: 180deg;
}

body[dir="rtl"]  .shapeSix .swiper-button-prev  {
  background-image: url('../../../../public/images/orange-right-slide.d2323afe.svg');
  rotate: 0deg;
  margin-right: 10px;
} 

body[dir="rtl"] .shapeSix .swiper .swiper-slide .title h3 {
    font-family: "arabic";
}


@media screen and (max-width: 767px) {
    .shapeSix .swiper {
       min-height: 100vh;
       padding:0 30px 30px !important;
       margin-top: 20px;
       margin-left: auto;
       text-align: center;
     
     }
     
     .shapeSix .swiper .swiper-slide {
       margin: 40px 20px;
       display: flex;
       justify-content: center !important;
       align-items: center;
       width: 16rem !important;
       height: 16rem !important;
     }
     .shapeSix .swiper-pagination-bullets.swiper-pagination-horizontal {
       top: 49% !important;
     }
   
     .shapeSix .swiper .swiper-slide .shapeContainer {
   
       height: 13rem !important;
       width: 13rem !important;
   
     }
     .shapeSix .swiper-button-next,
     .shapeSix .swiper-button-prev {
       display: none;
     }
     .shapeSix .swiper .swiper-slide .img {
        margin-top: 0px;
      }
      .shapeSix .swiper .swiper-slide .title h3 {
        font-size: 15px;
        transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
}
   }



   @media screen and (min-width:768px) and (max-width:991px){
    .shapeSix .swiper {
        min-height: 60vh;
        padding: 30px 0 !important;
        margin-top: 40px;
        margin-left: auto;
        text-align: center;
      }
    .shapeSix .swiper .swiper-slide {
        width: 22rem !important;
        height: 22rem !important;
        margin: 40px 10px !important;
        padding-left: 0px !important;
        
      }
      .shapeSix .swiper .swiper-slide .img {
        margin-top: 0px;
      }
   
      .shapeSix .swiper .swiper-slide .title h3 {
        font-size: 18px;
        width: 320px;
        transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
}
      .shapeSix .swiper-button-next,
      .shapeSix .swiper-button-prev {
        display: none !important;
      }
      .shapeSix .swiper-horizontal > .shapeSix .swiper-pagination-bullets,
      .shapeSix .swiper-pagination-bullets.swiper-pagination-horizontal {
        top:70% !important;
      }
}