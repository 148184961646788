@import "../../../index.scss";

.mainProject {
  padding-top: 60px;
  .item {
    .card {
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 300px;
      @include borderRadius(20px);
      margin-bottom: 25px;
      position: relative;
      overflow: hidden;
      .overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: #3c6484;
        opacity: 0.9;
        overflow: hidden;
        bottom: 0;
        left: -30%;
        top: 50%;
        width: 40rem;
        height: 40rem;
        border-radius: 50%;
        transform: none;
        text-align: left;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        padding: 2rem 20% 2rem 35%;

        h6 {
          padding-top: 1rem;
          width: 90%;
          font-size: 1rem;
          color: $textColor;
        }
        .two {
          @include borderRadius(30px);
          height: 50px;
          width: 150px;
          background-color: $textColor;
          color: $DarkBlueColor;
          border: 1px solid $textColor;
          @include transition();
          transform: translate(0px, 0px);
          -webkit-transform: translate(0px, 0px);
          -moz-transform: translate(0px, 0px);
          -ms-transform: translate(0px, 0px);
          -o-transform: translate(0px, 0px);
          &:hover {
            transform: translate(0px, 10px);
            -webkit-transform: translate(0px, 10px);
            -moz-transform: translate(0px, 10px);
            -ms-transform: translate(0px, 10px);
            -o-transform: translate(0px, 10px);
          }
          i {
            margin-left: 10px;
            color: $SecMainColor;
          }
        }
      }
    }
  }
}



body[dir="rtl"] {
  .mainProject {
    padding-top: 60px;
    .item {
      .card {
        .overlay {
          right: -30%;
          text-align: right;
          padding: 2rem 35% 2rem 20%;

          h6 {
            font-family: "arabic";
          }
          .two {
            font-family: "arabic";
            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
















@media screen and (max-width: 767px) {
  .mainProject {
    padding-top: 30px;
    .item {
      .card {
        .overlay {
          width: 25rem;
          height: 25rem;
          top: 45%;

          h6 {
            width: 90%;
            padding-top: 1rem;
            font-size: 16px
          }
          .two {
            @include borderRadius(30px);
            height: 50px;
            width: 150px;
            background-color: $textColor;
            color: $DarkBlueColor;
            border: 1px solid $textColor;
            @include transition();
            transform: translate(0px, 0px);
            -webkit-transform: translate(0px, 0px);
            -moz-transform: translate(0px, 0px);
            -ms-transform: translate(0px, 0px);
            -o-transform: translate(0px, 0px);
            &:hover {
              transform: translate(0px, 10px);
              -webkit-transform: translate(0px, 10px);
              -moz-transform: translate(0px, 10px);
              -ms-transform: translate(0px, 10px);
              -o-transform: translate(0px, 10px);
            }
            i {
              margin-left: 10px;
              color: $SecMainColor;
            }
          }
        }
      }
    }
  }
}
